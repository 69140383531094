import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, cutOffExcerpt, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Stars from "src/components/stars"
import SignupButton from "src/components/signupButton"

// Data
import capterraReviews from "src/content/home/capterraReviewsUS"

// Styles
import "src/styles/templates/home.scss"
import "src/styles/animations/home.scss"

// SEO
const title = "Timetastic - The PTO tracker for modern companies"
const description =
  "Timetastic is the super-simple PTO and vacation planner used by over 200,000 people to organise their time off work."

// JSX
const Home = (props) => {
  const reviews = props.data.allReviews.nodes
  const reviewStats = reviews[0].stats

  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        description={description}
        hrefLang={{
          EnHref: "https://timetastic.co.uk/",
          UsaHref: "https://timetastic.us/",
          UkHref: "https://timetastic.co.uk/",
        }}
      />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-us-home-main">
          <section className="c-section c-section-us-stars">
            <div className="u-inner u-inner--l">
              <div className="c-us-home-quotes">
                <div className="c-us-home-quote">
                  <p>“An absolute no-brainer”</p>
                </div>
                <div className="c-us-home-quote">
                  <p>“Fantastic super simple product!”</p>
                </div>
                <div className="c-us-home-quote">
                  <p>“Everyone here loves it”</p>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section c-us-home-hero" id="hero">
            <div className="u-inner u-inner--l c-us-home-hero__inner">
              <div>
                <h1 className="h h2 c-us-home-hero__title">
                  PTO and vacation tracking, solved.
                </h1>
                <p>
                  <strong>Without Timetastic,</strong> keeping track of PTO and
                  vacations is a nightmare. Spreadsheets and shared calendars.
                  It’s hard keeping everything up-to-date, yet another admin
                  burden to weigh you down.
                </p>
                <p>
                  <strong>With Timetastic,</strong> you’ve got a slick way to
                  request PTO on desktop or mobile. All your time off tracked
                  and recorded, and your calendar instantly updated. It’s a huge
                  weight off your shoulders.
                </p>
                <p className="c-us-home-hero__loop-arrow">
                  Let’s see how Timetastic works...
                </p>
              </div>
              <div className="c-us-home-hero__images">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-us-home-hero__tablet"
                  />
                  <StaticImage
                    src="../images/pto-hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-us-home-hero__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-us-home-hero__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>
          <section className="c-section is-animated">
            <div className="u-inner u-inner--l">
              <div className="c-us-home-features">
                <div className="c-us-home-feature c-us-home-feature-list">
                  <div className="">
                    <div className="c-us-home-feature-list__blob-01">
                      <BackgroundBlobs blobType="longlistBlobs">
                        <div className="u-inner u-inner--l">
                          <div className="c-us-home-feature-grid__item">
                            <div className="c-home-grid__annotation c-us-home-feature-grid__image-remaining">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Keep track of your PTO balance
                              </span>
                              <StaticImage
                                src="../images/calendars-remaining.jpg"
                                width={321}
                                alt="Remaining allowance"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-us-home-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-us-home-feature-grid__image-months">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Time off at a glance
                              </span>
                              <StaticImage
                                src="../images/calendars-months.jpg"
                                width={657}
                                alt="Calendar months"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-us-home-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-us-home-feature-grid__image-months-2">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Time off at a glance
                              </span>
                              <StaticImage
                                src="../images/calendars-months-2.jpg"
                                width={625}
                                alt="Calendar months"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-us-home-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-us-home-feature-grid__image-summary">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Summary of time off
                              </span>
                              <StaticImage
                                src="../images/pto-calendars-summary.jpg"
                                width={586}
                                alt="Leave summary"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-us-home-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-us-home-feature-grid__image-approved">
                              <StaticImage
                                src="../images/pto-calendars-approved.jpg"
                                width={586}
                                alt="Request approved"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-us-home-feature-grid__image"
                              />
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom c-home-grid__annotation--hide">
                                Instant email notifications
                              </span>
                            </div>
                            <StaticImage
                              src="../images/calendars-breakdown.jpg"
                              width={586}
                              alt="Your time off"
                              placeholder="blurred"
                              layout="constrained"
                              className="c-us-home-feature-grid__image c-us-home-feature-grid__image-breakdown"
                            />
                            <StaticImage
                              src="../images/calendars-trends.jpg"
                              width={586}
                              alt="Annual trends"
                              placeholder="blurred"
                              layout="constrained"
                              className="c-us-home-feature-grid__image c-us-home-feature-grid__image-trends"
                            />
                          </div>
                        </div>
                      </BackgroundBlobs>
                    </div>
                  </div>
                  <div>
                    <h2 className="h h4">It’s simple, and beautiful…</h2>
                    <ol>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          You get a slick PTO request policy
                        </h3>
                        <p>
                          You won’t need any scrappy PTO request forms anymore.
                          You log in to Timetastic, check the calendar, and
                          click the days you want off work. Done in an instant.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          You’ll be back in control of PTO
                        </h3>
                        <p>
                          Every PTO request is recorded and tracked as it’s
                          happening. You can see a tally of how many days off
                          anyone has taken and what balance they have left.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          An up-to-date vacation calendar
                        </h3>
                        <p>
                          Feed all your bookings directly into your personal or
                          team vacation calendar. The instant anyone books a
                          vacation - bang, calendars and schedules are all
                          updated.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          Never forget when someone is off
                        </h3>
                        <p>
                          Instead of coming into work and scratching your head
                          about where someone is, Timetastic will keep you
                          updated by sending a weekly schedule and daily
                          notification.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          An end to those messy spreadsheets
                        </h3>
                        <p>
                          You’ll get so much time back to yourself. You won’t
                          have to fiddle about with awkward spreadsheets, and
                          your team won’t need to keep asking how much PTO they
                          have left.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h3 className="h h5">
                          And you’ll never miss a birthday again
                        </h3>
                        <p>
                          The icing on the cake! Timetastic will even tell you
                          when it’s your colleague’s birthday, so you can
                          organise a nice card or cake. 🎂
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section u-text-centre">
            <div className="u-inner u-inner--l u-inner--hero is-unanimated">
              <div className="c-video c-video__explainer">
                <h2 className="h h2 u-text-centre">
                  See Timetastic in action
                </h2>
                <div className="c-video__player">
                  <lite-vimeo videoid="880192428"></lite-vimeo>
                </div>
                  <SignupButton
                    buttonCopy="Start free trial"
                    trackCtaLabel="USHomeVideoSignup"
                  />
                  <p className="c-free-trial__no-link">
                  Free for one month - no card required
                  </p>
              </div>
            </div>
          </section>
          <section className="c-section c-us-features-gradient">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated u-text-center u-text-left-mobile">
                <h2 className="h h2">
                  It’s got everything you need to{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    keep track of PTO
                  </span>{" "}
                  and your{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    team vacations
                  </span>
                </h2>
                <p>
                  The first thing you’ll see in Timetastic is your new
                  interactive wall chart. At a glance you can see who’s in and
                  who’s not. Click to request time off, use it to plan ahead,
                  make sure busy periods are covered and avoid any clashes.
                </p>

                <div className="c-home-grid__annotation">
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                    Your team
                  </span>
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                    Book time off
                  </span>
                  <StaticImage
                    src="../images/live-wallchart.jpg"
                    width={1200}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-us-home-feature-list-grid__image c-us-home-feature-list-grid__image-wallchart"
                  />
                  <StaticImage
                    src="../images/live-wallchart-mobile.jpg"
                    width={440}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-us-home-feature-list-grid__image c-us-home-feature-list-grid__image-wallchart-s"
                  />
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                    Pending request
                  </span>
                </div>
              </div>
            </div>
          </section>
          {/* Reviews list */}
          <section
            className="c-section c-us-home-reviews u-text-center u-text-left-mobile"
            id="reviews"
          >
            <div className="u-inner is-unanimated">
              <h2 className="h h2">
                Everyone{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  {" "}
                  loves using Timetastic
                </span>
              </h2>
            </div>
            <div className="u-inner u-inner--800 is-unanimated">
              <p className="">
                There’s currently over 170,000 people using Timetastic to book
                their PTO. Instead of manually updating spreadsheets and
                calendars, they open up Timetastic to check their vacation
                allowance. Then it’s just a few clicks to book a day off.
              </p>

              <ul className="c-reviews-list">
                {capterraReviews.map((review, index) => (
                  <li
                    className="c-reviews-list__item is-unanimated"
                    key={`review-l-${index}`}
                  >
                    <div className="c-reviews-list__content">
                      <p>‘{review.review}’</p>
                    </div>
                    <div className="c-reviews-list__avatar">
                      <img
                        src={review.avatar}
                        width="48px"
                        height="48px"
                        alt={review.reviewer}
                        loading="lazy"
                      />
                    </div>
                    <div className="c-reviews-list__reviewer">
                      <a
                        className="c-reviews-list__link"
                        href={review.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackCta("TestimonialsOutboundLinkUS")
                        }}
                      >
                        {review.reviewer}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Reviews carousel */}
          <section className="c-section c-us-home-reviews-grid is-unanimated">
            <div className="u-inner u-inner--l">
              <div className="c-reviews-section">
                <div className="c-reviews-section__top u-text-center u-text-left-mobile">
                  <h2 className="h h3">The 5 star reviews just keep coming</h2>
                  <div
                    aria-describedby={`Rated: ${
                      Math.ceil(reviewStats.average_rating * 2) / 2
                    } stars out of 5`}
                    style={{ color: "#fec000" }}
                  >
                    <Stars
                      namespace="total-reviews"
                      numberOfStars="5"
                      size={24}
                    />
                  </div>
                  <div>
                    <a
                      className="u-em-link u-hover-link"
                      href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        trackCta("HomeReviewsOutboundLinkUS")
                      }}
                    >
                      <span>
                        View all {reviewStats.total_reviews.toLocaleString()}{" "}
                        reviews{" "}
                      </span>
                      <svg
                        aria-hidden="true"
                        className="c-more-icon"
                        focussable="false"
                        height="16"
                        viewBox="0 0 20 16"
                        width="20"
                      >
                        <use xlinkHref="#svg-arrow" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="c-reviews">
                  {reviews.map((review, index) => (
                    <div
                      className="c-review"
                      key={`review-${review.store_review_id}-${review.user_id}`}
                    >
                      <div className="c-review__content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cutOffExcerpt(review.comments, 112),
                          }}
                        ></p>
                      </div>
                      <div
                        aria-describedby={`Rated: ${review.rating} stars out of 5`}
                        className="c-review__stars"
                      >
                        <Stars
                          namespace={`${review.store_review_id}-${review.user_id}`}
                          numberOfStars={parseFloat(review.rating)}
                        />
                      </div>
                      <div className="c-review__reviewer">
                        {review.reviewer.first_name} {review.reviewer.last_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-us-home-features-blocks">
                <div className="c-us-home-feature-block c-us-home-feature-block__regions is-unanimated">
                  <div>
                    <h3 className="h h3 u-text-center">Public holidays</h3>
                  </div>

                  <img
                    alt="Public holidays from 250 countries"
                    className="c-features-grid__imagex"
                    src="/images/home/us-states.svg"
                    width="516"
                    height="329"
                    loading="lazy"
                  />
                  <p className="u-text-center">
                    Every state is covered, from Alaska to Wyoming. Timetastic
                    supports over 3,000 regions from around the world.
                  </p>
                </div>
                <div className="c-us-home-feature-block c-us-home-feature-block__leave is-unanimated">
                  <ul className="u-text-center">
                    <li className="h h3">PTO</li>
                    <li className="h h3">Vacation</li>
                    <li className="h h3">Sickness</li>
                    <li className="h h3">Maternity</li>
                    <li className="h h3">Paternity</li>
                    <li className="h h3">Unpaid leave</li>
                    <li className="h h3">Bereavement</li>
                    <li className="h h3">Compassionate</li>
                    <li className="h h3">Jury duty</li>
                    <li className="h h3">Personal day</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section u-text-center">
            <div className="u-inner u-inner--l is-unanimated">
              <div className="c-us-home-feature-block c-us-home-feature-block__pricing">
                <h2 className="h h2">Free for your first 30 days</h2>
                <p>
                  Your can use Timetastic completely free for 30 days, you don’t
                  need to enter any payment details or phone numbers.
                </p>
                <p>
                  So you can start tracking PTO right away, see how it goes,
                  check it works for you, no questions asked.
                </p>
                <p className="c-us-home-feature-block__pricing-cta">
                  If after 30 days it’s looking good, then Timetastic is just
                  $1.50 per user/month.
                </p>
              </div>
            </div>
          </section>

          <FreeTrial
            body="Timetastic has been helping people book their PTO since 2012. Doctors, web design agencies, car dealers, online retailers, charities - over 8,000 businesses just like yours."
            buttonCopy="Start tracking PTO now"
            title="200,000+ people use Timetastic"
            trackCtaLabel="HomeSignUpFooterUS"
            imgClass="c-free-trial__user-graph"
            buttonHref="/pricing/"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}

export default Home

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
    allReviews {
      nodes {
        comments
        rating
        reviewer {
          first_name
          last_name
        }
        stats {
          average_rating
          total_reviews
        }
        store_review_id
        user_id
      }
    }
  }
`
